@import '../../style/var.scss';

.nft-email-verify-wrapper {
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        @include set-vw(margin-bottom, 20px);
    }
    .description {
        color: $GR-3;
    }
}