@import "../../style/var.scss";

.user-signup-language {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include set-vw(padding-top, 45px);
  @include set-vw(gap, 95px);

  & > .language-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include set-vw(gap, 10px);
  }

  & > .language-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    @include set-vw(gap, 17px);

    & > .language-button-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      border: 1px solid $GR-2;
      background-color: #ffffff00;

      @include set-vw(padding-top, 18px);
      @include set-vw(padding-bottom, 18px);
      @include set-vw(padding-left, 22px);
      @include set-vw(padding-right, 22px);
      @include set-vw(border-radius, 15px);

      transition: all 0.3s;

      &:hover {
        background-color: #ffffff07;
      }

      & > .language-button-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        @include set-vw(gap, 8px);

        & > img {
          @include set-vw(width, 28px);
          @include set-vw(height, 20px);
        }
      }

      & .check-icon {
        @include set-vw(width, 24px);
        @include set-vw(height, 24px);

        path {
          @include transition;
        }

        &#checked path {
          fill: $GN;
        }
      }
    }

    .languag-button-wrapper-checked {
      background-color: #ffffff19;
      border: 1px solid $GN;

      &:hover {
        background-color: #ffffff19;
      }
    }
  }

  & .signup-input-submit-button {
    @include set-vw(margin-top, 115px);
  }
}
