@import "../../style/var.scss";

.loader {
  margin: 0 0 2em;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  backdrop-filter: blur(5px);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
  animation: showFade 0.3s;

  .loading-title {
    font-size: 1.5em;
    font-weight: 500;
    color: $GN;
    margin-bottom: 0.5em;
    word-break: keep-all;
    line-height: 160%;
    white-space: pre-line;
  }

  svg {
    @include set-vw(width, 50px);
    @include set-vw(height, 50px);
  }

  svg path,
  svg rect {
    fill: $GN;
  }
}
