@import "./var.scss";
@import "./transform.scss";

@keyframes glitter {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-1vh);
  }
}
@-webkit-keyframes hideModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-1vh);
  }
}
@-moz-keyframes hideModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-1vh);
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
    transform: translateY(-1vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes showModal {
  0% {
    opacity: 0;
    transform: translateY(-1vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-moz-keyframes showModal {
  0% {
    opacity: 0;
    transform: translateY(-1vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes showDown {
  0% {
    opacity: 0;
    @include transform(translateY(-2vh));
  }
  100% {
    opacity: 1;
    @include transform(translateY(0));
  }
}
@-webkit-keyframes showDown {
  0% {
    opacity: 0;
    @include transform(translateY(-2vh));
  }
  100% {
    opacity: 1;
    @include transform(translateY(0));
  }
}
@-moz-keyframes showDown {
  0% {
    opacity: 0;
    @include transform(translateY(-2vh));
  }
  100% {
    opacity: 1;
    @include transform(translateY(0));
  }
}

@keyframes showFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showFadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showFadeOutReal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-3px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-3px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-3px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes showUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 2vh);
  }
  80% {
    transform: translate(-50%, -1vh);
  }
  100% {
    opacity: 1;
  }
}

@keyframes showBounceUp {
  0% {
    opacity: 0;
    transform: translateY(3vh);
  }
  80% {
    transform: translateY(-1vh);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes showBounceUp {
  0% {
    opacity: 0;
    transform: translateY(3vh);
  }
  80% {
    transform: translateY(-1vh);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes showBounceUp {
  0% {
    opacity: 0;
    transform: translateY(3vh);
  }
  80% {
    transform: translateY(-1vh);
  }
  100% {
    opacity: 1;
  }
}

@keyframes showBounceDown {
  0% {
    opacity: 0;
    transform: translateY(-3vh);
  }
  80% {
    transform: translateY(1vh);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes showBounceDown {
  0% {
    opacity: 0;
    transform: translateY(-3vh);
  }
  80% {
    transform: translateY(1vh);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes showBounceDown {
  0% {
    opacity: 0;
    transform: translateY(-3vh);
  }
  80% {
    transform: translateY(1vh);
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveLeft {
  100% {
    transform: translateX(-50%);
  }
}

@keyframes moveRight {
  100% {
    transform: translateX(50%);
  }
}

@keyframes showPop {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shinyStar {
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
}

@keyframes bounceSpace {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes showSheet {
  0% {
    opacity: 0;
    transform: translate(-50%, 1vh);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}

@-webkit-keyframes bounceSpace {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1vh);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes bounceSpace {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}
@-moz-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes blur {
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}
@-webkit-keyframes blur {
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}
@-moz-keyframes blur {
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}
