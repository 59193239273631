@import "../../../style/var.scss";

.yrp-accordian-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  transition: all 0.2s;

  position: relative;

  .header-line {
    width: 108%;
    height: 6px;
    background-color: $GR-1;
    position: absolute;

    @include MOBILE {
      width: 100vw;
    }
  }
}

.yrp-accordian-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include set-pair-vw(padding, 23px, 0px);
  box-sizing: border-box;
  border-bottom: 1px solid $GR-1;

  svg {
    transition: all 0.2s;
  }
}

.yrp-accordian-children {
  width: 100%;
  display: flex;
  @include set-pair-vw(padding, 23px, 0px);
  box-sizing: border-box;
  position: relative;
  opacity: 0;
  animation: accordian-open ease-in-out 0.4s forwards;

  &.close {
    animation: accordian-close ease-in-out 0.3s forwards;
  }
}

@keyframes accordian-open {
  from {
    opacity: 0;
    top: -10px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

@keyframes accordian-close {
  from {
    opacity: 1;
    top: 0px;
  }
  to {
    opacity: 0;
    top: -10px;
  }
}

.pay-method-wrapper,
.pay-result-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include set-vw(gap, 28px);
  @include set-vw(padding-bottom, 10px);

  .chain-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(gap, 12px);

    img {
      @include set-vw(width, 34px);
      @include set-vw(height, 34px);
    }

    .chain-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h2 {
        color: white;
      }

      h4 {
        color: $GR-3;
      }
    }
  }

  .content-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
  }

  .fee-box {
    width: 100%;
    @include set-pair-vw(padding, 21px, 20px);
    @include set-vw(border-radius, 15px);
    background-color: $GR-1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $GR-4;

    position: relative;

    box-sizing: border-box;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include set-vw(gap, 4px);
    }

    .charge-fee {
      color: $GR-6;
    }
  }

  .noti-wrapper {
    width: 15px;
    height: 15px;
    position: relative;
    z-index: 1;
  }
}

.bubble-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  animation: bubble-open ease-in-out 0.3s forwards;

  &.close {
    animation: bubble-close ease-in-out 0.3s forwards;
  }

  svg {
    position: relative;
    @include set-vw(top, 10px);
    @include set-vw(right, 30px);
  }

  &.eng {
    svg {
      @include set-vw(right, -1px);
    }
  }

  div {
    width: 100%;
    @include set-pair-vw(padding, 10px, 12.5px);
    background-color: #298bff;
    @include set-vw(border-radius, 15px);
    white-space: pre-line;
    word-break: keep-all;
    @include H7;
    color: white;
    line-height: 150%;
  }
}

@keyframes bubble-open {
  from {
    opacity: 0;
    @include set-vw(top, 35px);
  }
  to {
    opacity: 1;
    @include set-vw(top, 38px);
  }
}

@keyframes bubble-close {
  from {
    opacity: 1;
    @include set-vw(top, 38px);
  }
  to {
    opacity: 0;
    @include set-vw(top, 35px);
  }
}
