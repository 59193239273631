@import "../../style/var.scss";
@import "../../style/active.scss";

.nft-elem-wrapper {
  display: flex;
  flex-direction: column;
  border: 1.5px solid #4f4f4f;
  text-align: left;
  overflow: hidden;
  @include set-vw(width, 183px);
  @include set-vw(border-radius, 15px);
  @include active-scale(0.3s, 0.98);

  &.is-loading {
    border: none;
    overflow: hidden;

    .nft-image {
      border: none;
    }
  }

  &:nth-child(2n - 1) {
    margin-right: auto;
  }

  .nft-image {
    width: 100%;
    object-fit: cover;
    @include set-vw(height, 183px);
    @include set-vw(border-top-left-radius, 15px);
    @include set-vw(border-top-right-radius, 15px);
  }

  .nft-elem-body {
    @include set-vw(padding, 12px);

    .nft-name {
      width: -webkit-fill-available;
      font-weight: 500;
      @include set-vw(height, 22px);
      @include set-vw(font-size, 16px);
      @include overFlowText(1);
      margin-bottom: 2px;
    }

    .nft-reward-length {
      display: inline;
      min-width: 50%;
      color: $GR-4;
      @include set-vw(width, 100px);
      @include set-vw(height, 16px);
      @include set-vw(margin-top, 5px);
      @include set-vw(font-size, 14px);

      font-family: "MADE Okine Sans PERSONAL USE";
      font-weight: "400";
    }
  }

  .nft-elem-footer {
    @include set-vw(padding, 5px);
    @include set-vw(margin-bottom, 7px);

    button.nft-more-view {
      position: relative;
      display: flex;
      align-items: center;

      .more-view-icon {
        height: auto;
        @include set-vw(width, 18px);
      }

      .nft-more-view-wrapper {
        width: max-content;
        position: absolute;
        left: 0;
        bottom: calc(100% + 1vh);
        background: $BK;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        @include transition;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        @include set-vw(width, 130px);
        @include set-vw(gap, 15px);
        @include set-vw(padding-left, 8px);
        @include set-vw(padding-right, 8px);
        @include set-vw(padding-top, 11px);
        @include set-vw(padding-bottom, 11px);
        @include set-vw(border-radius, 5px);

        .nft-more-view-elem {
          display: flex;
          align-items: center;
          color: white;
          @include set-vw(gap, 5px);
          @include H5(true);

          svg {
            @include set-vw(width, 18px);
            @include set-vw(height, 18px);
          }
        }
      }

      &:focus {
        .nft-more-view-wrapper {
          opacity: 1;
          pointer-events: fill;
          @include show-bounce-up;
        }
      }
    }
  }

  .nft-elem-welcome-blur {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.69);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
}

.nft-elem-welcome {
  position: relative;
  margin-right: auto;
  white-space: pre-line;
  @include set-vw(width, 183px);
  @include active-scale(0.3s, 0.98);
  @include set-vw(border-radius, 15px);

  box-shadow: 0px 2px 20px 0px rgba(207, 255, 72, 0.2);

  .nft-welcome-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include set-vw(border-radius, 15px);

    @include set-vw(gap, 10px);
  }

  h5 {
    font-weight: 700;
    color: #8ff261;
    @include set-vw(line-height, 22px);

    &.eng {
      font-weight: 500;
    }
  }

  h6 {
    font-weight: 600;
    @include set-vw(line-height, 22px);

    &.eng {
      font-weight: 400;
    }
  }
}

.lock-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);

  position: absolute;

  @include set-vw(gap, 3px);
  @include set-vw(border-radius, 15px);
  @include set-pair-vw(padding, 5.5px, 10.5px);
  @include set-vw(top, 10px);
  @include set-vw(right, 10px);

  img {
    @include set-vw(width, 16px);
    aspect-ratio: 1;
  }

  span {
    font-family: "MADE Okine Sans PERSONAL USE";
    font-weight: 500;
    @include set-vw(font-size, 13px);
    color: $YE;
  }
}
