@import "../../style/var.scss";

.passcode-input-warning {
  .passcode-warning-wrapper {
    @include set-vw(margin-top, 46px);
    @include set-vw(margin-bottom, 30px);

    .passcode-warning-image {
      @include set-vw(width, 66px);
      @include set-vw(height, 66px);
      @include set-vw(margin-bottom, 14px);
    }

    .pass-code-warning-eng {
      @include set-vw(font-size, 20px);
      font-family: "MADE Okine Sans PERSONAL USE";
      font-weight: 500;
    }

    .warning-title {
      @include set-vw(font-size, 16px);
      @include set-vw(margin-top, 27px);
      @include set-vw(margin-bottom, 25px);
      color: $RE;
      font-family: "MADE Okine Sans PERSONAL USE";
      font-weight: 500;
    }

    .warning-description {
      line-height: 160%;
      white-space: pre-line;
    }
  }

  .passcode-input-wrapper {
    position: relative;
    @include set-vw(margin-bottom, 100px);

    .passcode-input {
      width: -webkit-fill-available;
      background: $BK;
      @include set-vw(border-radius, 15px);
      @include set-pair-vw(padding, 20px, 15px);
    }

    .passcode-error {
      position: absolute;
      left: 0;
      top: 115%;
      color: $RE;
      @include H5;
      @include animation-bounce;
    }
  }
}
