@import "../../style/var.scss";

.buttons-component-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @include set-vw(gap, 16px);
  position: sticky;
  @include set-vw(bottom, 30px);

  button {
    @include set-vw(height, 60px);
    @include set-vw(border-radius, 15px);
  }

  button:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include set-vw(gap, 6px);
    @include set-vw(width, 108px);
    background-color: black;
    color: white;

    svg {
      @include set-vw(height, 16px);
      width: auto;
    }
  }

  button:nth-child(2) {
    flex: 1;
    background-color: $PU;
    color: white;

    &:disabled {
      background-color: $GR-2;
    }
  }
}
