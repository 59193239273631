@import "../../../style/var.scss";
@import "../../../style/transform.scss";

.floating-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @include set-vw(gap, 18px);
  position: relative;
  z-index: 1000;

  .plus-button {
    @include set-vw(width, 68px);
    @include set-vw(height, 68px);
    border-radius: 100%;
    background: linear-gradient(
        149deg,
        #1c09ff 18.54%,
        rgba(115, 230, 255, 0) 318.97%
      ),
      #1355ff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    @include transition(0.2s);

    .plus-icon {
      @include set-vw(width, 24px);
      @include set-vw(height, 24px);
      @include transition(0.2s);

      &.open {
        @include rotate(-45);
      }
    }

    &:hover {
      @include transform(translateY(-2px));
    }

    &:active {
      @include transform(translateY(1px));
    }
  }

  .floating-children-wrapper {
    position: relative;
    opacity: 0;

    .floating-children-caption {
      position: absolute;

      top: 50%;
      @include set-vw(right, 70px);
      @include translate(0%, -50%);

      @include H5;
      color: $GR-6;

      white-space: nowrap;

      &.eng {
        @include H3(true);
      }
    }

    .floating-children-button {
      @include set-vw(width, 52px);
      @include set-vw(height, 52px);
      border-radius: 100%;
      background-color: $DB;

      display: flex;
      align-items: center;
      justify-content: center;

      .floating-children-icon {
        height: 39%;
        width: auto;
        position: relative;
        @include transition(0.2s);
      }
    }

    @include animationExtends(
      items-open,
      0.3s,
      ease-in-out,
      0s,
      1,
      normal,
      forwards
    );

    &:nth-child(1) {
      animation-delay: 0.3s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.1s;
    }

    &:hover {
      .floating-children-icon {
        @include rotate(-20);
      }
    }

    &:active {
      .floating-children-icon {
        @include rotate(-23);
      }
    }

    &:nth-child(1) {
      .floating-children-icon {
        left: -0.6px;
      }
    }
    &:nth-child(3) {
      .floating-children-icon {
        left: 1.2px;
      }
    }

    &.close {
      @include animationExtends(
        items-close,
        0.3s,
        ease-in-out,
        0s,
        1,
        normal,
        forwards
      );
    }
  }
}

@keyframes items-open {
  0% {
    opacity: 0;
    @include translate(0, 5px);
  }
  100% {
    opacity: 1;
    @include translate(0, 0px);
  }
}

@keyframes items-close {
  0% {
    opacity: 1;
    @include translate(0, 0px);
  }
  100% {
    opacity: 0;
    @include translate(0, 5px);
  }
}

.floating-container {
  width: 100%;
  height: 100vh;
  background-color: #00000091;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  box-sizing: border-box;

  @include set-vw(padding, 20px);
  @include animation(showFade, 0.5s, forwards);

  &.close {
    @include animation(showFadeOutReal, 0.3s, forwards);
  }
}

.wallet-alert {
  position: absolute;
  background-color: $RE;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  @include set-vw(width, 30px);
  @include set-vw(height, 30px);
  @include set-vw(right, -5px);
  @include set-vw(top, -5px);
  @include set-vw(font-size, 15px);
}
