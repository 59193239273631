@import "../../../style/var.scss";

.nft-list-container {
  width: 100%;
  min-height: 40vh;
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  @include transition(1s);
  @include set-vw(row-gap, 20px);
  @include set-vw(margin-top, 20px);
  @include set-vw(margin-bottom, 100px);

  align-items: start;
  justify-content: center;

  &.entered {
    @include show-down(1s);
  }

  .empty-wrapper {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .empty-message {
    color: $GR-4;
    font-weight: 400;
    white-space: pre-line;
  }

  .no-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include H3;
    @include set-vw(height, 400px);

    .empty-text {
      margin: auto;
      display: inline;
      line-height: 150%;
      color: $GR-4;
      white-space: pre-line;

      h2 {
        display: inline;
        word-break: break-all;
        color: white;
        font-weight: 500;
      }

      &.eng {
        @include H3(true);
      }

      & .empty-text-caption {
        @include set-vw(font-size, 20px);
        font-weight: 400;
      }
    }
  }
}

.external-nft-wrapper {
  &.available {
    display: flex;
    flex-direction: column;
    border: solid white;
    overflow: hidden;
    position: relative;
    @include transition;
    @include set-vw(border-width, 1.5px);
    @include set-vw(border-radius, 15px);

    &.checked {
      border-color: $GN;
    }

    .check-icon {
      position: absolute;
      color: $GN;
      @include set-vw(top, 10px);
      @include set-vw(right, 8px);
      @include set-vw(width, 24px);
      @include set-vw(height, 24px);

      &.unchecked path {
        fill: white;
      }
    }
  }
}
