@import "../../style/var.scss";

.signup {
  .signup-header {
    border-bottom: 1px solid $GR-3;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include set-vw(height, 64px);

    .go-back {
      position: absolute;
      left: 0;
      @include set-vw(top, 24px);
      @include set-vw(width, 24px);
      @include set-vw(height, 24px);
    }
  }

  .signup-input-wrapper {
    display: flex;
    flex-direction: column;
    @include set-vw(margin-top, 33px);
    @include set-vw(gap, 33px);

    .input-box {
      display: flex;
      flex-direction: column;
      @include set-vw(gap, 10px);

      .input-label {
        text-align: left;
        @include H4;
      }

      .input-label-eng {
        text-align: left;
        @include H3(true);
      }

      .input-description {
        font-weight: 400;
        color: $GR-4;
        text-align: left;
        @include set-vw(font-size, 12px);

        a {
          color: $GR-4;
          text-decoration: underline;
        }
      }

      .input-text {
        width: -webkit-fit-available;
        padding: 17px 15px;
        background: $GR-1;
        flex-grow: 1;
        @include set-vw(border-radius, 10px);
        @include H5;
      }

      .input-phone-number-wrapper {
        display: flex;
        @include set-vw(gap, 10px);

        .input-national-code {
          @include set-vw(width, 40px);

          padding: 17px 15px;
          background: $GR-1;
          @include set-vw(border-radius, 10px);
          @include H5;
        }

        .input-text {
          width: -webkit-fill-available;
          flex-shrink: 1;
          flex-grow: 1;
          @include set-vw(width, 100px);
        }

        .button {
          min-width: max-content;
          padding: 0 !important;
          flex-shrink: 0;
          @include H5;
          @include set-vw(width, 126px);
          @include set-vw(height, 54px);
          @include set-vw(border-radius, 10px);
        }
      }

      .phone-verfication-wrapper {
        width: -webkit-fit-available;
        padding: 17px 15px;
        background: $GR-1;
        display: flex;

        @include set-vw(border-radius, 10px);
        @include set-vw(gap, 10px);
        @include H5;

        input[type="number"] {
          flex-grow: 1;
          @include H5;
        }

        .verify-status {
          &#success {
            color: $GN;
          }
          &#fail {
            color: $RE;
          }
        }
      }

      .term-of-use-wrapper {
        display: flex;
        flex-direction: column;
        @include set-vw(gap, 26px);
        @include set-vw(margin-top, 24px);

        .term-of-use {
          display: flex;
          align-items: center;
          @include set-vw(gap, 10px);
          @include set-vw(font-size, 18px);

          &#select-all {
            @include set-vw(padding-bottom, 26px);
            border-bottom: 1px solid $GR-3;
          }

          .check-icon {
            @include set-vw(width, 24px);
            @include set-vw(height, 24px);
            flex-shrink: 0;

            path {
              @include transition;
            }

            &#checked path {
              fill: $GN;
            }
          }

          .term-of-use-text {
            flex-grow: 1;
            text-align: left;
            word-break: keep-all;
          }

          .eng {
            @include set-vw(font-size, 17px);
            font-family: "MADE Okine Sans PERSONAL USE";
            font-weight: 400;
          }

          .term-of-use-link {
            width: auto;
            @include set-vw(height, 15px);
          }
        }

        input {
          display: none;
        }
        input[type="checkbox"]:checked + .term-of-use .check-icon path {
          fill: $GN;
        }
      }
    }

    .signup-input-submit-button {
      @include set-vw(margin-top, 33px);
      @include set-vw(margin-bottom, 66px);
    }
  }
}
