@import "../../../style/var.scss";

.external-chain-select-container {
  width: 100%;
  position: relative;

  .selected-chain-box {
    width: 100%;
    @include set-vw(height, 54px);
    @include set-vw(border-radius, 10px);
    @include set-pair-vw(padding, 13px, 12px);
    @include set-vw(padding-right, 18px);
    cursor: pointer;

    box-sizing: border-box;

    background-color: $GR-1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .selected-chain-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(gap, 6px);

    h4 {
      color: $GR-4;
      font-weight: 400;
    }
  }

  .chain-select-caret {
    @include transition(0.3s);
  }

  .chain-icon {
    @include set-vw(width, 28px);
    height: auto;
  }

  .chain-select-list {
    width: 100%;
    position: absolute;

    z-index: 1;
    @include set-vw(top, 60px);
    left: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background: rgba(51, 51, 51, 0.8);
    backdrop-filter: blur(7.5px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
    @include set-vw(border-radius, 10px);

    overflow: hidden;

    animation: chain-modal-open 0.5s ease-in-out forwards;

    &.close {
      animation: chain-modal-close 0.3s ease-in-out forwards;
    }
  }

  .chain-line {
    width: 100%;
    @include set-vw(height, 55px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(gap, 6px);
    @include set-pair-vw(padding, 0px, 12px);
    box-sizing: border-box;

    cursor: pointer;
    @include transition(0.2s);

    &:hover {
      background: rgba(51, 51, 51, 0.071);
    }

    h4 {
      font-weight: 400;
    }
  }
}
