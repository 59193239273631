@import '../../style/var.scss';
@import '../../style/transform.scss';
@import '../../style/active.scss';

.select-input {
    position: relative;
    z-index: 3;

    &#disabled {
        pointer-events: none;
        .selected-value h5 { color: $GR-3; }
        .arrow-down-icon { display: none; }
    }

    .select-input-value {
        width: -webkit-fill-available;
        background-color: $GR-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include set-vw(gap, 8px);
        @include set-vw(border-radius, 10px);
        @include set-pair-vw(padding, 14px, 12px);

        .selected-value {
            display: flex;
            align-items: center;
            color: white;
            @include set-vw(gap, 6px);
            
            .selected-value-icon {
                object-fit: cover;
                object-position: center;
                @include set-vw(width, 28px);
                @include set-vw(height, 28px);
            }
        }

        .placeholder { flex-shrink: 0; color: $GR-4; }

        .arrow-down-icon {
            @include transition(0.4s);
            @include set-vw(width, 24px);
            @include set-vw(height, 24px);
        }
    }

    .select-input-option-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $BK;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.5px);
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 115%;
        display: none;
        z-index: 3;
        @include set-vw(border-radius, 10px);

        .select-input-option {
            display: flex;
            align-items: center;
            color: white;
            @include set-vw(gap, 6px);
            @include set-pair-vw(padding, 14px, 12px);
            @include active-color($GR-0);

            &#disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            .select-input-option-icon {
                object-fit: cover;
                object-position: center;
                @include set-vw(width, 28px);
                @include set-vw(height, 28px);
            }
        }
    }


    .select-input-value:focus {
        .arrow-down-icon {
            @include rotateZ(180);
        }

        .select-input-option-list {
            display: block;
            @include show-down;
        }
    }
}