@import "../../style/var.scss";
@import "../../style/transform.scss";
@import "../../style/flex.scss";

.yours-wallet-page {
  .yours-wallet-title {
    text-align: left;
    @include set-vw(margin-bottom, 20px);
  }

  .yours-wallet-container {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    border-bottom: solid $GR-1;
    @include set-vw(gap, 20px);
    @include set-vw(padding-bottom, 23px);
    @include set-vw(border-width, 6px);
  }

  .external-wallet-button {
    width: 100%;
    @include set-vw(height, 64px);
    @include set-vw(border-radius, 15px);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include set-vw(gap, 6px);

    color: $GR-1;

    img {
      @include set-vw(height, 24px);
      width: auto;

      &.wallet-icon {
        @include set-vw(height, 14px);
      }
    }

    h5 {
      &.eng {
        @include H4;
      }
    }

    &.connect {
      border: 1px solid var(--Green, #d2f586);
      background: var(
        --gradation,
        linear-gradient(96deg, #34e97c 3.67%, #fff3c7 107%)
      );
    }

    &.disconnect {
      background-color: $GR-2;
      color: white;
    }
  }
}
