@import "../../style/var.scss";
@import "../../style/transform.scss";
@import "../../style/active.scss";

button.tutorial-step {
  width: -webkit-fill-available;
  background: $GR-2;
  color: white;
  position: relative;
  @include active-scale;
  @include set-vw(padding, 16px);
  @include set-vw(padding-bottom, 27px);
  @include set-vw(border-radius, 15px);

  &#finished {
    opacity: 0.5;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.4);

    .tutorial-step-finished {
      opacity: 1;
    }
  }

  .tutorial-step-finished {
    position: absolute;
    border-radius: 50%;
    background-color: $GN;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    opacity: 0;
    bottom: 50%;
    @include transition;
    @include transform(translateY(50%) rotate(-20.05deg));
    @include set-vw(right, 20px);
    @include set-vw(width, 64px);
    @include set-vw(height, 64px);
    @include H5(true);
  }

  div {
    h5 {
      max-width: 80%;
      text-align: left;
      font-weight: 400;
    }
  }
}
