@import "../../../style/var.scss";

.charge-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include set-vw(gap, 20px);

  position: relative;
  z-index: 1;
}

.yrp-confirm-info-wrapper {
  @include set-vw(padding-bottom, 100px);
}

.yrp-confirm-info-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @include set-vw(gap, 20px);
  @include set-pair-vw(padding, 26px, 0px);
}

.yrp-passcode-wrapper {
  width: 100%;
  min-height: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @include set-pair-vw(padding, 60px, 0px);
  @include set-vw(gap, 90px);

  .passcode-input-wrapper {
    width: 100%;
    @include set-vw(height, 54px);
    @include set-vw(border-radius, 10px);
    background-color: #333333;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @include set-pair-vw(padding, 10px, 18px);
    box-sizing: border-box;

    input {
      flex: 1;
    }
  }

  .fail-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(gap, 12px);
    color: white;
    position: relative;
    @include set-vw(top, -75px);
    @include set-vw(left, 10px);

    .circle {
      @include set-vw(width, 16px);
      @include set-vw(height, 16px);
      background-color: $RE;
      border-radius: 100%;
    }
  }
}

.yrp-loading-wrapper {
  width: 100%;
  min-height: 83vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include set-vw(gap, 7px);
}

.yrp-result-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @include set-vw(padding-bottom, 50px);

  .title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include set-vw(gap, 30px);
    white-space: pre-line;

    @include set-vw(padding-top, 30px);
    @include set-vw(padding-bottom, 118px);

    h6 {
      color: $GR-4;
      font-weight: 400;
    }
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(gap, 20px);

    .content-line {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: white;

      h5:nth-child(1) {
        font-weight: 400;
        color: $GR-4;
      }

      .noti-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        @include set-vw(gap, 5px);
      }

      .chain-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include set-vw(gap, 4px);
        @include set-vw(gap, 10px);
        img {
          @include set-vw(width, 28px);
          @include set-vw(height, 28px);
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          h4:nth-child(2) {
            color: $GR-3;
          }
        }
      }
    }
  }
}
