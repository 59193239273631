@import "../../../style/var.scss";

.history-modal-background {
  width: 100vw;
  height: 100vh;
  background-color: #00000062;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;

  animation: sheet-background-open 0.3s ease-in-out forwards;

  &.close {
    animation: sheet-background-close 0.3s ease-in-out forwards;
  }

  .history-modal {
    width: 100%;
    max-width: 450px;
    background-color: $GR-2;
    border-radius: 20px 20px 0px 0px;
    box-sizing: border-box;

    @include set-pair-vw(padding, 35px, 24px);
    @include set-vw(padding-top, 3px);

    position: relative;
    opacity: 0;
    bottom: 10px;

    animation: bottom-sheet-open 0.5s ease-in-out forwards;

    &.close {
      animation: bottom-sheet-close 0.5s ease-in-out forwards;
    }
  }

  .modal-header {
    width: 100%;
    @include set-vw(height, 70px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .caption {
      @include set-vw(font-size, 20px);
      color: white;
      font-weight: 500;

      &.eng {
        font-family: MADE Okine Sans PERSONAL USE;
      }
    }
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(gap, 20px);

    & > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & > h4:nth-child(1) {
        font-weight: 400;
        color: $GR-4;
      }

      & > h4:nth-child(2),
      .chain-wrapper,
      .noti-caption {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include set-vw(gap, 4px);
      }

      .chain-wrapper {
        @include set-vw(gap, 10px);
        img {
          @include set-vw(width, 28px);
          @include set-vw(height, 28px);
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          h4:nth-child(2) {
            color: $GR-3;
          }
        }
      }
    }
  }

  .tx-hash-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(gap, 4px);
    @include set-vw(font-size, 18px);
    color: $GR-4;

    & span:nth-child(1) {
      border-bottom: 1px solid $GR-4;
    }
  }
}

@keyframes sheet-background-open {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes sheet-background-close {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes bottom-sheet-open {
  from {
    opacity: 0;
    bottom: -20px;
  }
  to {
    opacity: 1;
    bottom: 0px;
  }
}

@keyframes bottom-sheet-close {
  from {
    opacity: 1;
    bottom: 0px;
  }
  to {
    opacity: 0;
    bottom: -20px;
  }
}
