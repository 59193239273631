@import "../../../style/var.scss";

.google-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @include set-vw(margin-top, 20px);

  .form-info-text {
    @include H6;
    color: $GR-4;
  }

  .link {
    color: $GR-6;
    cursor: pointer;
    @include set-vw(padding-bottom, 2px);
    border-bottom: 1px solid $GR-6;
  }
}
