@import "../../../style/var.scss";

.modal-wrapper.disabled-popup {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @include set-vw(padding-left, 35px);
  @include set-vw(padding-right, 35px);
  @include set-vw(padding-top, 30px);
  @include set-vw(padding-bottom, 30px);
  @include set-vw(width, 336px - 38px);
  @include set-vw(max-width, 350px);
  @include set-vw(gap, 30px);

  .not-entry-sign {
    @include set-vw(width, 118px);
    height: auto;
  }

  .disabled-popup-title {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: keep-all;
    @include set-vw(height, 80px);
    @include set-vw(font-size, 17px);
    @include set-vw(margin-bottom, 20px);
    line-height: 145%;

    text-align: center;
    white-space: pre-line;
  }

  .disabled-popup-title-eng {
    @include H3(true);
    text-align: center;
    white-space: pre-line;
  }

  .button.disabled-popup-button {
    @include set-vw(border-radius, 15px);
    @include set-vw(padding, 16px);

    &#black {
      background-color: $GR-1;
    }
  }

  .modal-x-mark {
    position: absolute;
    @include set-vw(top, 20px);
    @include set-vw(right, 20px);
    @include set-vw(width, 32px);
    @include set-vw(height, 32px);
    cursor: pointer;
  }
}
