@import "../../../style/var.scss";
@import "../../../style/active.scss";

.charge-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include set-vw(gap, 18px);
  @include show-down;
  box-sizing: border-box;

  h3 {
    color: white;
    text-align: start;
  }

  .charge-input-box {
    width: 100%;
    border: 1px solid $GR-2;
    background-color: #333333;
    @include set-pair-vw(padding, 14px, 25px);
    @include set-vw(border-radius, 15px);
    position: relative;
    outline: none;

    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    input {
      display: flex;
      flex: 1;
      @include set-vw(font-size, 20px);
      font-weight: 500;
      color: white;
      min-width: 0px;
    }
  }

  .over-egg {
    color: $GR-4;
    font-weight: 400;
    position: relative;
    @include set-vw(left, 10px);
    @include set-vw(top, -5px);
    text-align: start;

    &.insufficient {
      color: $RE;
    }
  }

  .warning {
    color: $RE;
    position: relative;
    @include set-vw(left, 10px);
    @include set-vw(top, -5px);
    text-align: start;
  }
}

.charge-input-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @include set-vw(gap, 6px);
  transition: all 0.2s;
  @include active-scale;
  box-sizing: border-box;

  img {
    @include set-vw(width, 28px);
    @include set-vw(height, 28px);
  }

  svg {
    transition: all 0.3s;
  }

  h3 {
    font-weight: 400;
    white-space: nowrap;
  }
}

.charge-chain-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @include set-vw(border-radius, 15px);
  background-color: $GR-1;
  box-sizing: border-box;

  position: absolute;
  @include set-vw(top, 70px);
  right: 0px;
  z-index: 1;

  overflow: hidden;

  opacity: 0;

  animation: chain-modal-open 0.5s ease-in-out forwards;

  &.close {
    animation: chain-modal-close 0.3s ease-in-out forwards;
  }

  .caption {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include set-vw(height, 54px);
    @include set-pair-vw(padding, 16px, 25px);
    border-bottom: 1px solid $GR-2;
    box-sizing: border-box;
  }

  .line {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include set-pair-vw(padding, 11px, 23px);
    box-sizing: border-box;
    @include set-vw(gap, 12px);

    transition: all 0.2s;

    &:hover {
      background-color: $GR-0;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    img {
      @include set-vw(width, 28px);
      @include set-vw(height, 28px);
    }

    .name {
      font-weight: 400;
      color: white;
    }

    .full {
      font-weight: 400;
      color: $GR-3;
    }
  }
}

@keyframes chain-modal-open {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes chain-modal-close {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
