@import "../../style/var.scss";

.star-loading {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 160%;
  @include H3;
  @include set-vw(gap, 60px);

  .eng {
    font-family: "MADE Okine Sans PERSONAL USE";
  }

  .star-image {
    height: auto;
    animation: rotate 3.5s linear infinite;
    @include set-vw(width, 165px);

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  b {
    @include H2(true);
  }
}
