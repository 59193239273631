@import "../../../style/var.scss";

.external-wallet-header {
  width: 100%;
  @include set-vw(height, 70px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.address-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @include set-vw(gap, 10px);

  .wallet-logo {
    @include set-vw(width, 29px);
    @include set-vw(height, 29px);
    @include set-vw(border-radius, 7px);
  }

  .address-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    @include set-vw(gap, 5px);

    .copy-logo {
      @include set-vw(width, 16px);
      @include set-vw(height, 16px);
      cursor: pointer;
    }
  }
}
