@import "../../style/var.scss";
@import "../../style/active.scss";
@import "../../style/transform.scss";

.profile-edit-page {
  .profile-edit-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;

    .profile-image-wrapper {
      width: 100%;
      border-bottom: solid #333333;
      @include set-vw(border-width, 6px);
      @include set-pair-vw(padding, 24px, 0px);

      label {
        margin: auto;
      }
    }

    .profile-input-wrapper {
      width: -webkit-fill-available;
      display: flex;
      flex-direction: column;

      .profile-input-elem {
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $GR-2;
        @include set-vw(gap, 28px);
        @include set-vw(min-height, 60px);
        @include set-pair-vw(padding, 11px, 0px);
        @include H5;

        box-sizing: border-box;

        .input-title {
          color: $GR-4;
          flex-shrink: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          @include set-vw(min-width, 50px);
        }

        .en {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          @include set-vw(min-width, 112px);
        }

        .input {
          text-align: left;
          flex-grow: 1;
          @include H5;
          @include set-vw(width, 100px);

          overflow: hidden;
        }

        button {
          background-color: $BK;
          color: white;
          flex-shrink: 0;
          @include set-vw(width, 78px);
          @include set-vw(height, 37px);
          @include set-vw(border-radius, 10px);
          @include active-scale;

          &.save-edit {
            background-color: $PU;
          }
        }
      }
    }
  }

  .logout {
    width: fit-content;
    margin-right: auto;
    text-align: left;
    color: $GR-3;
    @include set-vw(margin-top, 32px);
    @include H5;
  }
}

.lang-select {
  position: relative;

  & > .lang-select-box {
    background-color: black;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include set-vw(border-radius, 10px);
    @include set-vw(padding-left, 12px);
    @include set-vw(padding-right, 12px);
    @include set-vw(padding-top, 8px);
    @include set-vw(padding-bottom, 8px);

    gap: 4px;

    position: relative;

    & > .select-chevron {
      @include set-vw(width, 13px);
      height: auto;
      transition: all 0.2s;

      @include rotate(90);
    }

    & > .open {
      @include rotate(-90);
    }
  }

  & > .lang-select-modal {
    width: 100%;
    position: absolute;
    @include set-vw(top, 40px);
    @include set-vw(border-radius, 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #333333d0;
    @include show-modal(0.5s);

    & span {
      @include set-vw(height, 43px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
