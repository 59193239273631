@import "../../../style/var.scss";

.wallet-modal-container {
  @include set-vw(width, 386px);
  @include set-vw(border-radius, 20px);
  overflow: hidden;
  background-color: $GR-1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;

  @include animation(showModal, 0.5s, forwards);

  .modal-header {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }

  .modal-content-wrapper {
    width: 100%;
    @include set-pair-vw(padding, 0px, 10px);
    @include set-vw(padding-bottom, 14px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;

    ul {
      width: 100%;
    }

    li {
      width: 100%;
      height: 72px;
      @include set-pair-vw(padding, 0px, 12px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      @include set-vw(border-radius, 10px);

      &:hover:not(.chain-wrapper) {
        background-color: #1e1e1e;
      }
    }

    .chain-name {
      font-weight: 500;
      color: $GR-4;
    }

    .chain-info-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      @include set-vw(gap, 14px);

      h3 {
        font-weight: 400;
        color: $GR-4;
      }

      span {
        @include set-vw(width, 42px);
        @include set-vw(height, 42px);
        @include set-vw(border-radius, 10px);
        background-color: black;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;

          &.Pontem,
          &.Martian {
            width: 70%;
            height: 70%;
          }
        }
      }
    }
  }
}
