@import "../../style/var.scss";

.external-nft-container {
  min-height: 70vh;
}

.transfer-warning {
  color: #828282;
  @include set-vw(margin-bottom, 28px);
  @include H6;

  white-space: pre-line;
}

.transfer-status-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @include set-vw(gap, 48px);

  white-space: pre-line;

  .iconify {
    @include set-vw(width, 60px);
    @include set-vw(height, 60px);
  }

  .transfer-warning {
    color: $GR-3;
  }
}

.loading-icon {
  @include set-vw(width, 80px);
  @include set-vw(height, 80px);

  path,
  rect {
    fill: #ed5f8a;
  }
}

.transfer-confirm-top-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include set-vw(gap, 48px);
}

.transfer-status-wrapper {
  line-height: 250%;

  white-space: pre-line;

  .transfer-ttile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include set-vw(gap, 5px);
    font-weight: 400;
  }
}

.transfer-confirm-wrapper {
  width: 100%;

  .transfer-confirm-text {
    @include set-vw(margin-top, 24px);
    font-weight: 400;
  }
}
