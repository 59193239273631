@import "../../../style/var.scss";

.yrp-history-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.yrp-amount-box {
  width: 100%;
  background-color: $GR-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include set-vw(gap, 34px);

  @include set-pair-vw(padding, 35px, 22px);
  position: relative;

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include set-vw(gap, 12px);
    color: white;
  }

  .buttons-wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include set-vw(gap, 15px);
  }
}

.history-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .caption {
    width: 100%;
    text-align: start;
    @include set-pair-vw(padding, 22px, 23px);
  }
}
