@import "../../../style/var.scss";

.benefit-bubble-position {
  position: absolute;
  @include set-vw(bottom, 45px);
  @include show-bounce-up();

  .benefit-bubble-wrapper {
    @include set-vw(width, 390px);
    padding: 34px 15px 16px 14px;
    position: relative;
    box-sizing: border-box;
    @include set-vw(border-radius, 15px);
    background: linear-gradient(326deg, #000 57.14%, rgba(0, 0, 0, 0) 148.64%);
    backdrop-filter: blur(5px);
    text-align: center;
    color: $GR-5;
  }

  .benefit-bubble-polygon {
    position: absolute;

    @include set-vw(bottom, -15px);
    left: 65%;
  }

  .benefit-bubble-xmark {
    position: absolute;

    @include set-vw(top, 15px);
    @include set-vw(right, 15px);
  }
}
