@import '../../style/var.scss';
@import '../../style/active.scss';

.page-footer {
    white-space: pre-wrap;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $BK;
    color: $GR-4;
    @include set-vw(padding-top, 34px);
    @include set-vw(padding-bottom, 54px);
    @include set-vw(gap, 34px);
    @include set-vw(font-size, 14px);
    @include set-vw(line-height, 17px);

    .page-footer-description {
        line-height: 165%;
    }

    .service-guide-link {
        background: $PU;
        color: white;
        @include set-vw(font-size, 12px);
        @include set-vw(border-radius, 20px);
        @include set-pair-vw(padding, 10px, 20px);
        @include active-scale;
    }
    
    .contact-link-wrapper {
        display: flex;
        align-items: center;
        @include set-vw(gap, 30px);

        img {
            @include set-vw(width, 36px);
            @include set-vw(height, 36px);
        }
    }

    .policy-link-wrapper {
        display: flex;
        @include set-vw(gap, 20px);

        a {
            color: $GR-4;
        }
    }
}