@import "../../../style/var.scss";

.reward-info-form {
  .reward-info-content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
    @include set-vw(gap, 20px);
  }

  .input-caption {
    text-align: start;
    font-weight: 400;
    color: $GR-4;
  }

  .benefit-type-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include set-vw(gap, 10px);
    @include set-vw(margin-top, 15px);
  }
}
