@import "../../../style/var.scss";

.reward-detail-page {
  text-align: left;

  .content-wrapper {
    @include set-vw(margin-bottom, 50px);

    &.bottom {
      min-height: 60vh;
    }
  }

  .title {
    word-break: break-all;
    @include set-vw(margin-bottom, 25px);
  }

  .description {
    line-height: 160%;
    white-space: pre-wrap;
    word-break: break-all;
    font-weight: 400;
    @include set-vw(font-size, 16px);
  }

  .button-wrapper {
    position: sticky;
    width: 100%;
    display: flex;
    @include set-vw(bottom, 30px);
    @include set-vw(gap, 15px);
  }

  .benefit-type-header {
    width: 100%;
    @include set-pair-vw(padding, 28px, 0px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .benefit-name {
      font-weight: 400;
      color: $GR-4;
    }
  }

  .benefit-type-content {
    width: 100%;
    @include set-vw(height, 80px);

    h3 {
      color: $GR-4;
    }
  }

  .benefit-link-wrapper {
    width: 100%;
    @include set-vw(height, 54px);
    @include set-vw(border-radius, 10px);
    box-sizing: border-box;
    background-color: #333333;
    color: $GR-3;

    @include set-pair-vw(padding, 17px, 15px);
    @include set-vw(margin-top, 10px);
  }
}
