@import "../../../style/var.scss";

.category-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include set-vw(gap, 16px);

  box-sizing: border-box;
  @include set-pair-vw(padding, 18px, 19px);
  @include set-vw(gap, 5px);
  @include set-vw(border-radius, 15px);

  @include transition;

  background-color: black;
  color: white;

  position: relative;

  .border {
    width: 100%;
    height: 100%;
    border: 2px solid $PU;
    @include transition;
    opacity: 1;
    box-sizing: border-box;

    position: absolute;
    top: 0px;
    left: 0px;

    @include set-vw(border-radius, 15px);
  }

  &.disabled {
    background-color: $GR-1;
    color: $GR-3;

    img {
      filter: grayscale(100%);
    }

    .border {
      opacity: 0;
    }
  }
}
