@import "../../../style/var.scss";
@import "../../../style/active.scss";

.mini-button {
  flex: 1;
  @include set-vw(height, 63px);
  @include set-vw(border-radius, 15px);
  @include H3;

  color: white;
  background-color: $GR-0;

  &.eng {
    @include H3(true);
    cursor: default;
  }

  &:not(&:disabled) {
    @include active-scale;
  }

  &:disabled {
    color: $GR-3;
    background-color: $GR-2;
  }
}
