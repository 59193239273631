@import "../../style/var.scss";
@import "../../style/transform.scss";

.wallet-elem-wrapper {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  background-color: $BK;
  position: relative;
  text-align: left;
  @include set-vw(gap, 6px);
  @include set-vw(border-radius, 15px);
  @include set-pair-vw(padding, 20px, 18px);

  .wallet-address-box {
    width: fit-content;
    display: flex;
    cursor: pointer;
    @include set-vw(gap, 5px);

    .copy-icon {
      @include set-vw(width, 16px);
      @include set-vw(height, 16px);
    }
  }

  .wallet-more-button {
    position: absolute;
    z-index: 3;
    @include set-vw(width, 18px);
    @include set-vw(height, 18px);
    @include set-vw(right, 18px);
    @include set-vw(bottom, 30px);

    .wallet-more-menu-elem {
      display: flex;
      background: #f2f2f2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      position: absolute;
      right: 0;
      display: none;
      @include set-pair-vw(padding, 12px, 8px);
      @include set-vw(min-width, 168px - 16px);
      @include set-vw(gap, 5px);
      @include set-vw(border-radius, 5px);

      white-space: nowrap;

      .wallet-more-menu-text {
        color: $BK;
      }

      svg {
        @include set-vw(width, 18px);
        @include set-vw(height, 18px);

        path {
          fill: $BK;
        }
      }
    }

    &:focus .wallet-more-menu-elem {
      display: inline-flex;
      @include show-bounce-down;
    }
  }

  .more-icon {
    cursor: pointer;
    @include rotate(90);
    @include set-vw(width, 18px);
    @include set-vw(height, 18px);

    rect {
      @include transition;
    }
    &:active rect {
      fill: $GR-4 !important;
    }
  }
}
