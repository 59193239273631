@import "../../../style/var.scss";

.history-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include set-pair-vw(padding, 18px, 22px);
  @include set-vw(gap, 6px);

  transition: all 0.2s;
  border-radius: 5px;

  &:hover {
    background-color: $GR-1;
  }

  &.failure {
    color: $GR-3;
  }

  .logo {
    @include set-vw(width, 30px);
    @include set-vw(height, 30px);
  }

  .content-line {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @include set-vw(gap, 6px);

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      @include set-vw(gap, 6px);
    }

    .timestamp {
      color: $GR-3;
      text-align: start;
    }

    svg {
      @include set-vw(width, 18px);
      @include set-vw(height, 18px);
    }
  }

  .icon {
    @include set-vw(width, 30px);
    height: auto;
  }

  .amount-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    @include set-vw(gap, 5px);

    svg {
      @include set-vw(height, 15px);
      width: auto;
    }
  }
}

.history-empty-wrapper {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $GR-4;
}
