@import "../../style/var.scss";
@import "../../style/animation.scss";

.bottom-sheet-component {
  @include set-vw(width, 430px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include set-vw(gap, 18px);
  @include set-vw(padding-top, 52px);
  @include set-vw(padding-bottom, 50px);
  border-radius: 20px 20px 0px 0px;
  background-color: #000000d0;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0px);

  @include animation-show-up;

  .title {
    font-weight: 700;
  }

  .positive {
    color: $GN-T;
  }

  .negative {
    color: $RE;
  }

  h5 {
    font-weight: 400;
    color: white;
    white-space: pre-line;
    text-align: center;
    @include set-vw(line-height, 20px);
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    @include set-vw(gap, 12px);
    @include set-vw(margin-top, 30px);
    @include set-pair-vw(padding, 0px, 14px);
    box-sizing: border-box;

    button {
      flex: 1;
      @include set-vw(border-radius, 15px);
      @include H5;
      height: 60px;
      color: white;
    }

    .close {
      border: 1px solid $GR-3;
    }
    .confirm {
      background-color: $PU;
    }
  }
}

:root {
  --blurvh: 0;
}

.blur-background-wrapper {
  width: 100%;
  height: calc(var(--blurvh) * 100);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;

  .blur-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    @include animation-fade-in;
  }
}
