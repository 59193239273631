@import "../../style/var.scss";

.modal-wrapper.popup {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(7.5px);
  @include set-vw(padding-left, 19px);
  @include set-vw(padding-right, 19px);
  @include set-vw(padding-top, 30px);
  @include set-vw(padding-bottom, 30px);
  @include set-vw(width, 336px - 38px);
  @include set-vw(max-width, 350px);

  .popup-title {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: keep-all;
    @include set-vw(height, 80px);
    @include set-vw(font-size, 17px);
    @include set-vw(margin-bottom, 20px);

    text-align: center;
    white-space: pre-line;
  }

  .popup-title-eng {
    @include H3(true);
    text-align: center;
    white-space: pre-line;
  }

  .popup-button-wrapper {
    display: flex;
    justify-content: center;
    @include set-vw(gap, 10px);

    .button.popup-button {
      max-width: 60%;
      @include set-vw(border-radius, 15px);
      @include set-vw(padding, 16px);

      &#black {
        background-color: $GR-1;
      }
    }
  }
}
